import React, { useEffect, useState } from "react";
import { Controller, useWatch } from "react-hook-form";
import {
	FormControl, InputLabel, MenuItem, Select, TextField
} from "@mui/material";

import ToolsArea from "../Components/ToolsArea";
import { useFetch } from "../../Hooks/useFetch";
import DeleteItem from "../Components/DeleteItem";
import EditModForm from "../Components/Forms/EditModForm";
import EnteSelect from "../Components/Forms/EnteSelect";
import Operatori from "../Components/Forms/Operatori";
import SpinnyPage from "../Components/SpinnyPage";
import DataGrid from "../Components/Tables/DataGrid";
import useTableColumns from "../Utils/useTableColumns";
import { DataGridSelectColumn } from "../Components/Tables/DataGridSelect";

export default function Pos({ account }) {
	const enteOperatore = account.ente?.codiceEnte;

	const [createEditMode, setCreateEditMode] = useState(false);
	const [deleteOpen, setDeleteOpen] = useState(false);
	const [refresh, setRefresh] = useState(0);
	const [query, setQuery] = useState({ page: 0, pageSize: 20 });
	const [selectedRows, setSelectedRows] = useState(new Map());
	const selected = [...selectedRows];

	const { data } = useFetch('/pos/query', null, 'POST', query, refresh);

	if (data) data.data = data.data.map((x) => ({ ...x, id: `${x.ente.codiceEnte}///${x.identificativoPos}` }));

	const { visibleColumns, allColumns, setVisible, visible, setOrder, sort, sortFilter, setSort } = useTableColumns("pos", [
		DataGridSelectColumn,
		!enteOperatore && { key: "codiceEnte", name: "Codice ente", sortColumn: "idPos.ente.codiceEnte", sortable: true, frozen: false /*true*/, draggable: false, resizable: true, highlight: true, minWidth: 200, width: 240 },
		{ key: "distributore", name: "Distributore", sortColumn: "idPos.vendor", sortable: true, frozen: false /*true*/, draggable: true, highlight: true, resizable: true, minWidth: 200, width: 200 },
		{ key: "identificativo", name: "Identificativo", sortColumn: "idPos.identificativoPos", sortable: true, frozen: false /*true*/, draggable: false, highlight: true, minWidth: 300, width: 300, resizable: true },
		{ key: "nome", name: "Nome", sortColumn: "nome", sortable: true, draggable: false, resizable: true, highlight: true, minWidth: 400, width: 400 },
		{ key: "descrizione", name: "Descrizione", sortColumn: "descrizione", sortable: true, draggable: false, resizable: true }
	], [DataGridSelectColumn.key, !enteOperatore && "codiceEnte", "identificativo", "distributore", "nome", "descrizione"]);

	useEffect(() => setQuery((q) => ({ ...q, orderBy: sortFilter })), [JSON.stringify(sortFilter)]);

	const tableData = data?.data?.map((r) => ({
		id: r.id,
		codiceEnte: r.ente.codiceEnte ?? "",
		identificativo: r.identificativoPos ?? "",
		distributore: r.vendor ?? "",
		nome: r.nome ?? "",
		descrizione: r.descrizione ?? ""
	}));

	if (!data) return <SpinnyPage />;

	const Vendor = ({ control, nullItem, required, disabled }) => (
		<FormControl variant="standard" className="w-100">
			<InputLabel>Distributore</InputLabel>
			<Controller
				name="vendor"
				defaultValue="NUMERA"
				control={control}
				render={({ field: { onChange, value, ref } }) => (
					<Select
						required={required}
						inputRef={ref}
						disabled={disabled}
						inputProps={{ value: value || 'NUMERA' }}
						onChange={onChange}
					>
						{ nullItem && <MenuItem defaultChecked value="">Nessun filtro</MenuItem>}
						<MenuItem value="NUMERA">Numera</MenuItem>
					</Select>
				)}
			/>
		</FormControl>
	);

	const editCreateForm = ({ register, control, mode }) => {
		const ente = useWatch({ name: 'ente.codiceEnte', control });

		if (!ente && mode === "edit") return (<></>);

		const { data: operatori } = useFetch('/operatore/query', null, 'POST', { ente: { codiceEnte: enteOperatore ?? ente } }, 0);

		return (
			<div className="flex-column d-flex w-100">
				{!enteOperatore && <Controller name="ente.codiceEnte" control={control} render={({ field: { onChange, value } }) => <EnteSelect required onChange={onChange} value={value} disabled={mode === 'edit'} />} />}
				<TextField required {...register('identificativoPos')} label="Identificativo" inputProps={{ maxLength: 100 }} disabled={mode === 'edit'} variant="standard" className="mt-2" />
				<div className="mt-2"><Vendor control={control} required disabled={mode === 'edit'} /></div>
				<TextField required {...register('nome')} label="Nome" inputProps={{ maxLength: 100 }} variant="standard" className="mt-2" />
				<TextField {...register('descrizione')} label="Descrizione" inputProps={{ maxLength: 2000 }} variant="standard" className="mt-2" />
				<div className="mt-3">
					<Operatori control={control} operatori={(enteOperatore || ente) ? operatori?.data : []} />
				</div>
			</div>
		);
	};

	const FilterFields = [
		[
			(r) => (<TextField {...r('ente.codiceEnte')} label="Codice ente" variant="standard" inputProps={{ maxLength: 50 }} />),
			(r, c) => (<div style={{ width: "200px" }}><Vendor control={c} /></div>),
			(r) => (<TextField {...r('identificativoPos')} label="Identificativo" variant="standard" />),
			(r) => (<TextField {...r('nome')} label="Nome" variant="standard" />)
		]
	];

	const firstSelectedPos = data.data.filter((d) => d.id === selected[0])[0];
	const canModify = ["All", "GestionePos"].some((p) => account.ruolo.permessi.includes(p));

	return (
		<div>
			<DeleteItem
				open={deleteOpen}
				query="/pos/delete"
				entityNamePlural="pos"
				entityNameSingle="pos"
				items={selected.map((item) => {
					const pos = data.data.find((x) => x.id === item);
					return pos
						? {
							identificativoPos: pos.identificativoPos,
							vendor: pos.vendor,
							ente: { codiceEnte: pos.ente.codiceEnte }
						}
						: {};
				})}
				ente={selected.map((item) => {
					const pos = data.data.find((x) => x.id === item);
					return pos ? pos.ente.codiceEnte : null;
				})}
				onCancel={() => setDeleteOpen(false)}
				onDeleted={() => { setDeleteOpen(false); setRefresh(refresh + 1); setSelectedRows(new Set()); }}
				onError={() => { setDeleteOpen(false); setSelectedRows(new Set()); }}
			/>
			<EditModForm
				path={`/pos${createEditMode === 'edit' ? `/${firstSelectedPos.ente.codiceEnte}/${firstSelectedPos.identificativoPos}` : ''}`}
				entityName="Pos"
				ente={createEditMode === 'edit' ? firstSelectedPos.ente.codiceEnte : null}
				mode={createEditMode}
				data={createEditMode === 'edit' ? firstSelectedPos : null}
				onCancel={() => setCreateEditMode(false)}
				onSuccess={() => { setCreateEditMode(false); setRefresh(refresh + 1); }}
				formElements={editCreateForm}
			/>
			<ToolsArea
				className="mt-4 mb-3"
				selected={selected}
				setSelected={setSelectedRows} //Serve per svuotare gli operatori selezionati se effettuo una ricerca
				queryParameters={[
					'idPos.ente.codiceEnte', 'nome', 'idPos.identificativoPos', 'idPos.vendor', 'descrizione'
				]}
				disableDelete={!canModify}
				disableEdit={!canModify}
				disableNew={!canModify}
				filtersChanged={(filters) => setQuery({ ...query, ...filters })}
				deleteCallback={() => setDeleteOpen(true)}
				createCallback={() => setCreateEditMode('create')}
				editCallback={() => setCreateEditMode('edit')}
				fields={FilterFields}
				allColumns={allColumns}
				visibleColumns={visible}
				setVisibleColumns={setVisible}
			/>
			<DataGrid
				columns={visibleColumns}
				rows={tableData}
				query={query?.query?.split(" ") ?? []}
				onSortColumnsChange={(col) => setOrder(col.map((c) => c.key))}
				rowKeyGetter={(row) => row.id}
				totalResults={data.totalResults}
				selectedRows={selectedRows}
				setSelectedRows={setSelectedRows}
				setPage={(page) => setQuery({ ...query, page })}
				currPage={query.page}
				sortData={sort}
				onSortDataChange={setSort}
			/>
			<br />
			<br />
			<br />
		</div>
	);
}
