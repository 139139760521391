/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable eqeqeq */
import {
	FormControl,
	FormControlLabel, IconButton,
	InputLabel,
	MenuItem,
	Select,
	Switch,
	TextField
} from '@mui/material';
import moment from "moment";
import { DateTimePicker, DesktopDatePicker, DateRangePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment as DateAdapter } from "@mui/x-date-pickers/AdapterMoment";
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { useFetch } from '../../Hooks/useFetch';
import DataTableColumn from '../Components/Tables/DataTableColumn';
import ToolsArea from '../Components/ToolsArea';
import DeleteItem from "../Components/DeleteItem";
import EnteLogoInput from "../Components/Forms/EnteLogoInput";
import EditModForm, { EditFormContext } from "../Components/Forms/EditModForm";
import SmallDataTable from "../Components/Tables/SmallDataTable";
import SpinnyPage from "../Components/SpinnyPage";
import DataGrid from "../Components/Tables/DataGrid";
import useTableColumns from "../Utils/useTableColumns";
import { DataGridSelectColumn } from "../Components/Tables/DataGridSelect";

export default function Enti(params) {
	// - Table data
	const [refresh, setRefresh] = useState(0);
	const [query, setQuery] = useState({ page: 0, pageSize: 20, orderBy: [{ field: "codiceEnte", desc: false }] });
	const { data } = useFetch('/ente/query', null, 'POST', query, refresh);
	const [deleteOpen, setDeleteOpen] = useState(false);
	const [createEditMode, setCreateEditMode] = useState(false);
	const [selectedRows, setSelectedRows] = useState(new Map());

	const { visibleColumns, allColumns, setVisible, visible, setOrder, sort, sortFilter, setSort } = useTableColumns("enti", [
		DataGridSelectColumn,
		{ key: "codiceEnte", name: "Codice ente", sortColumn: "codiceEnte", sortable: true, frozen: false /*true*/, draggable: false, resizable: true, highlight: true, minWidth: 200, width: 240 },
		{ key: "codiceFiscale", name: "Identificativo fiscale", sortColumn: "intestatario.codiceFiscale", sortable: true, frozen: false /*true*/, draggable: false, highlight: true, resizable: true, minWidth: 200, width: 200 },
		{ key: "denominazione", name: "Denominazione", sortColumn: "intestatario.denominazione", sortable: true, frozen: false, draggable: false, highlight: true, minWidth: 500, resizable: true },
		{ key: "tipoCreditore", name: "Tipo creditore", sortColumn: "categoria.nome", sortable: true, draggable: false, resizable: true, highlight: true, minWidth: 200, width: 200 },
		{ key: "stato", name: "Stato", sortColumn: "stato", sortable: true, draggable: false, resizable: true, width: 80 }
	], [DataGridSelectColumn.key, "codiceEnte", "codiceFiscale", "denominazione", "tipoCreditore", "stato"]);

	useEffect(() => setQuery((q) => ({ ...q, orderBy: sortFilter })), [JSON.stringify(sortFilter)]);

	const tableData = data?.data?.map((r) => ({
		id: r.codiceEnte ?? "",
		codiceEnte: r.codiceEnte ?? "",
		codiceFiscale: r.intestatario.codiceFiscale ?? "",
		denominazione: r.intestatario.denominazione ?? "",
		tipoCreditore: r.categoria.nome ?? "",
		stato: r.stato ?? ""
	}));

	if (!data) return <SpinnyPage />;

	const CategoriaEnte = ({ control, rules, nullItem = false }) => {
		const { data: categorie, status } = useFetch('/ente/categorie', null, 'GET');

		const [state, dispatch] = useContext(EditFormContext) ?? [null, () => {}];
		const previousState = useRef("");

		useEffect(() => {
			if (previousState.current !== status && status === "fetching") dispatch({ type: "AddCounter" });
			if (previousState.current !== status && status === "fetched") dispatch({ type: "DecreaseCounter" });
			if (previousState.current !== "fetching" && status === "idle") dispatch({ type: "ResetCounter" });
			previousState.current = status;
		}, [status]);

		return categorie && (
			<Controller
				name="categoria.codiceCategoria"
				defaultValue=""
				control={control}
				rules={rules}
				render={({ field: { onChange, value } }) => (
					<FormControl variant="standard" className="w-100">
						<InputLabel id="tipoEnte">Tipo ente</InputLabel>
						<Select labelId="tipoEnte-label" id="tipoEnte" label="Tipo ente" value={value ?? ''} onChange={onChange} required>
							{nullItem && (<MenuItem key="" value="">Nessun filtro</MenuItem>)}
							{
								categorie && categorie.map((categoria) => (
									<MenuItem
										key={categoria.codiceCategoria}
										value={categoria.codiceCategoria}
									>
										{categoria.nome}
									</MenuItem>
								))
							}
						</Select>
					</FormControl>
				)}
			/>
		);
	};

	const editCreateForm = ({ register, control, mode, errors, setValue }) => {
		const codiceFiscale = useWatch({ name: 'intestatario.codiceFiscale', control, defaultValue: null });
		const sendReport = useWatch({ name: 'sendReport', control });
		const weekReport = useWatch({ name: 'weekReport', control });
		const monthReport = useWatch({ name: 'monthReport', control });
		const [ibanPage, setIbanPage] = useState(0);
		const [certPage, setCertPage] = useState(0);

		return (
			<div className="flex-column d-flex ">
				<div className="flex-row d-flex ">
					<div className="w-100">
						<TextField required {...register('intestatario.codiceFiscale')} disabled={mode === 'edit'} label="Codice fiscale" inputProps={{ maxLength: 30 }} variant="standard" className="mt-2 w-100" />
						<TextField required {...register('codiceEnte')} disabled={mode === 'edit'} label="Codice ente" variant="standard" inputProps={{ maxLength: 50 }} className="mt-2 w-100" />
						<TextField required {...register('intestatario.denominazione')} label="Denominazione" inputProps={{ maxLength: 256 }} variant="standard" className="mt-2 w-100" id="ragioneSociale" />
						<div className="w-100 mt-3"><CategoriaEnte control={control} rules={{ required: true }} errors={errors} /></div>
						<div className="mt-3">
							<h4>Canali di pagamento</h4>
							<Controller name="modello1" defaultValue control={control} render={({ field: { onChange, value } }) => <FormControlLabel control={<Switch onChange={onChange} checked={value} />} label="Pagamento sul circuito pagoPA (Modello 1)" />} />
							<Controller name="modello3" defaultValue control={control} render={({ field: { onChange, value } }) => <FormControlLabel control={<Switch onChange={onChange} checked={value} />} label="Pagamento presso il PSP (Modello 3)" />} />
							<TextField {...register('gln')} label="Codice GLN" inputProps={{ maxLength: 13 }} variant="standard" className="mt-2 w-100" id="codiceGln" />
							<TextField {...register('codiceCBILL')} label="Codice CBILL" inputProps={{ maxLength: 5 }} variant="standard" className="mt-2" id="cbill" style={{ width: '200px' }} />
							<TextField required {...register('codiceStazione')} label="Codice Stazione" inputProps={{ maxLength: 2 }} variant="standard" className="mt-2 ml-3" id="codiceStazione" style={{ width: '180px' }} />
						</div>
					</div>
					<div className="pl-5 w-100">
						<TextField {...register('intestatario.partitaIva')} inputProps={{ maxLength: 11 }} label="Partita IVA" variant="standard" className="mt-2 w-100" id="partitaIva" />
						<TextField {...register('intestatario.indirizzo')} inputProps={{ maxLength: 256 }} label="Indirizzo" variant="standard" className="mt-2 w-50" id="indirizzo" />
						<TextField {...register('intestatario.numeroCivico')} inputProps={{ maxLength: 6 }} label="N Civico" variant="standard" className="mt-2 w-25 ml-3" id="numeroCivico" />
						<TextField {...register('intestatario.comune')} inputProps={{ maxLength: 50 }} label="Comune" variant="standard" className="mt-2 w-50" id="comune" />
						<TextField {...register('intestatario.provincia')} inputProps={{ maxLength: 2 }} label="Provincia" variant="standard" className="mt-2 w-25 ml-3" id="provincia" />
						<TextField {...register('intestatario.nazione')} inputProps={{ maxLength: 50 }} label="Nazione" variant="standard" className="mt-2 w-50" id="nazione" />
						<TextField {...register('intestatario.cap')} inputProps={{ maxLength: 5 }} label="CAP" variant="standard" className="mt-2 w-25 ml-3" id="cap" />
						<TextField {...register('intestatario.telefono')} inputProps={{ maxLength: 20 }} label="Telefono" variant="standard" className="mt-2 w-100" id="telefono" />
						<TextField {...register('intestatario.fax')} inputProps={{ maxLength: 20 }} label="FAX" variant="standard" className="mt-2 w-100" id="fax" />
						<TextField {...register('intestatario.sitoWeb')} inputProps={{ maxLength: 256 }} label="Sito Web" variant="standard" className="mt-2 w-100" id="sitoWeb" />
						<Controller name="logo" control={control} render={({ field: { onChange, value } }) => (<EnteLogoInput onChange={onChange} value={value} />)} />
					</div>
				</div>
				<div className="mt-3 w-100">
					<h4>Configurazione Report</h4>
					<Controller name="sendReport" defaultValue={false} control={control} render={({ field: { onChange, value } }) => <FormControlLabel control={<Switch onChange={onChange} checked={value} />} label="Abilita Invio Automatico" />} />
					{sendReport
						? (
							<Controller
								name="weekReport"
								defaultValue
								control={control}
								render={({ field: { onChange, value } }) =>
									(
										<FormControlLabel
											control={
												(
													<Switch
														onChange={(x) => {
															onChange(x.target.checked);
															if (!x.target.checked && !monthReport) setValue("monthReport", true);
														}}
														checked={value}
													/>
												)}
											label="Abilita Report Settimanale"
										/>
									)}
							/>
						)
						: <></>}
					{sendReport
						? (
							<Controller
								name="monthReport"
								defaultValue
								control={control}
								render={({ field: { onChange, value } }) =>
									(
										<FormControlLabel
											control={
												(
													<Switch
														onChange={(x) => {
															onChange(x.target.checked);
															if (!x.target.checked && !weekReport) setValue("weekReport", true);
														}}
														checked={value}
													/>
												)}
											label="Abilita Report Mensile"
										/>
									)}
							/>
						)
						: <></>}
				</div>
				<div className="mt-3 w-100">
					<h4>Configurazione IBAN</h4>
					<Controller
						name="ibans"
						defaultValue={[]}
						control={control}
						render={({ field: { onChange, value } }) => (
							<SmallDataTable
								onPageChange={(page) => setIbanPage(page)}
								page={ibanPage}
								data={value ? value.slice(ibanPage * 5, ibanPage * 5 + 5) : []}
								pageSize={5}
								totalCount={value ? value.length : 0}
								idField="iban"
							>

								<DataTableColumn
									renderHeader={() => (
										<IconButton onClick={() => onChange([...value, { iban: "", partitaIva: codiceFiscale, new: true, tipo: 'Bancario' }])}>
											<i className="fas fa-fw fa-plus-circle" />
										</IconButton>
									)}
									renderContent={(r, i) => (
										<IconButton onClick={() => onChange(value.filter((_, idx) => idx != i))}>
											<i className="fas fa-fw fa-minus-circle" />
										</IconButton>
									)}
									padding={10}
									min
								/>

								<DataTableColumn
									renderHeader={() => "IBAN"}
									renderContent={(r, i) => (
										<TextField
											value={value[i].iban}
											onChange={(e) => onChange(value.map((v, idx) => (idx == i
												? { ...v, iban: e.target.value } : v)))}
											inputProps={{ maxLength: 35 }}
											InputProps={{ disableUnderline: true }}
											disabled={!value[i].new}
											label="IBAN"
											variant="standard"
											className="w-100"
										/>
									)}
									padding={10}
								/>

								<DataTableColumn
									renderHeader={() => "Codice Fiscale"}
									renderContent={(r, i) => (
										<TextField
											value={value[i].partitaIva}
											onChange={(e) => onChange(value.map((v, idx) => (idx == i
												? { ...v, partitaIva: e.target.value } : v)))}
											InputProps={{ disableUnderline: true }}
											disabled
											label="Codice Fiscale"
											variant="standard"
											className="w-100"
										/>
									)}
									padding={10}
								/>

								<DataTableColumn
									renderHeader={() => "TIPO"}
									renderContent={(r, i) => (
										<FormControl variant="standard" className="w-100">
											<Select
												style={{ width: "200px" }}
												labelId="tipoEnte-label"
												id="tipo"
												label="Tipo"
												disabled={!value[i].new}
												disableUnderline
												inputProps={{ value: value[i].tipo ?? 'Bancario' }}
												onChange={(e) => onChange(value.map((v, idx) => (idx == i
													? { ...v, tipo: e.target.value } : v)))}
											>
												<MenuItem defaultChecked value="Bancario">Bancario</MenuItem>
												<MenuItem value="Postale">Postale</MenuItem>
											</Select>
										</FormControl>
									)}
									padding={10}
									min
								/>
							</SmallDataTable>
						)}
					/>
				</div>
				<div className="mt-3 w-100">
					<h4>Configurazione Certificati</h4>
					<LocalizationProvider dateAdapter={DateAdapter}>
						<Controller
							name="certificati"
							defaultValue={[]}
							control={control}
							render={({ field: { onChange, value } }) => (
								<SmallDataTable
									onPageChange={(page) => setCertPage(page)}
									page={certPage}
									data={value ? value.slice(certPage * 5, certPage * 5 + 5) : []}
									pageSize={5}
									totalCount={value ? value.length : 0}
									idField="sha"
								>

									<DataTableColumn
										renderHeader={() => (value.length < 2 ? (
											<IconButton onClick={() => onChange([...value, { sha: "", validita: moment().format("yyyy-MM-DD"), new: true }])}>
												<i className="fas fa-fw fa-plus-circle" />
											</IconButton>
										) : "")}
										renderContent={(r, i) => (
											<IconButton onClick={() => onChange(value.filter((_, idx) => idx != i))}>
												<i className="fas fa-fw fa-minus-circle" />
											</IconButton>
										)}
										padding={10}
										min
									/>

									<DataTableColumn
										renderHeader={() => "SHA"}
										renderContent={(r, i) => (
											<TextField
												value={value[i].sha}
												onChange={(e) => onChange(value.map((v, idx) => (idx == i
													? { ...v, sha: e.target.value } : v)))}
												InputProps={{ disableUnderline: true }}
												disabled={!value[i].new}
												label="Fingerprint del certificato"
												variant="standard"
												className="w-100"
											/>
										)}
										padding={10}
									/>

									<DataTableColumn
										renderHeader={() => "VALIDITÀ"}
										renderContent={(r, i) => (
											<div style={{ width: "300px" }}>
												<DesktopDatePicker
													label="Data di validità"
													inputFormat="DD/MM/yyyy"
													disabled={!value[i].new}
													value={value[i].validita}
													onChange={(e) => onChange(value.map((v, idx) => (idx == i
														? { ...v, validita: e } : v)))}
													renderInput={(ps) => <TextField variant="standard" className="w-100 mt-3" {...ps} />}
												/>
											</div>
										)}
										padding={10}
										min
									/>
								</SmallDataTable>
							)}
						/>
					</LocalizationProvider>
				</div>
			</div>
		);
	};

	const FilterFields = [
		[
			(r) => (<TextField {...r('codiceEnte')} label="Codice ente" variant="standard" inputProps={{ maxLength: 50 }} />),
			(r) => (<TextField {...r('intestatario.denominazione')} label="Denominazione" variant="standard" inputProps={{ maxLength: 50 }} />),
			(r, c) => (<div style={{ width: '250px' }}><CategoriaEnte control={c} nullItem /></div>),
			(r) => (<TextField {...r('intestatario.codiceFiscale')} label="Codice fiscale" variant="standard" inputProps={{ maxLength: 50 }} />),
			(r) => (<TextField {...r('intestatario.partitaIva')} label="Partita IVA" variant="standard" inputProps={{ maxLength: 50 }} />),
			(r, c) => (
				<Controller
					name="stato"
					defaultValue=""
					control={c}
					render={({ field: { onChange, value } }) => (
						<div style={{ width: '150px' }}>
							<FormControl variant="standard" className="w-100">
								<InputLabel id="stato">Stato ente</InputLabel>
								<Select labelId="tipoEnte-label" id="stato" label="Stato" onChange={onChange} value={value}>
									<MenuItem value="">Nessun filtro</MenuItem>
									<MenuItem value="Attivo">Attivo</MenuItem>
									<MenuItem value="Disattivo">Disattivo</MenuItem>
									<MenuItem value="Nascosto">Nascosto</MenuItem>
								</Select>
							</FormControl>
						</div>
					)}
				/>
			)
		]
	];

	return (
		<div style={{ maxWidth: "calc(100%)", flexGrow: 0 }}>
			<DeleteItem
				open={deleteOpen}
				query="/ente/delete"
				entityNamePlural="enti"
				entityNameSingle="ente"
				items={[...selectedRows].map((item) => ({ codiceEnte: item }))}
				onCancel={() => setDeleteOpen(false)}
				onDeleted={() => { setDeleteOpen(false); setRefresh(refresh + 1); setSelectedRows(new Set()); }}
				onError={() => setDeleteOpen(false)}
			/>
			<EditModForm
				path="/ente"
				entityName="Ente"
				ente={createEditMode === 'edit' ? [...selectedRows][0] : null}
				mode={createEditMode}
				data={createEditMode === 'edit' ? data.data.filter((d) => d.codiceEnte === [...selectedRows][0])[0] : null}
				onCancel={() => setCreateEditMode(false)}
				onSuccess={() => { setCreateEditMode(false); setRefresh(refresh + 1); }}
				formElements={editCreateForm}
			/>
			<ToolsArea
				className="mt-4 mb-3"
				selected={[...selectedRows]}
				setSelected={setSelectedRows} //Serve per svuotare gli operatori selezionati se effettuo una ricerca
				queryParameters={['codiceEnte', 'intestatario.denominazione', 'intestatario.codiceFiscale', 'intestatario.nome', 'categoria.nome']}
				filtersChanged={(filters) => setQuery({ ...query, ...filters })}
				deleteCallback={() => setDeleteOpen(true)}
				createCallback={() => setCreateEditMode('create')}
				editCallback={() => setCreateEditMode('edit')}
				fields={FilterFields}
				allColumns={allColumns}
				visibleColumns={visible}
				setVisibleColumns={setVisible}
			/>
			<DataGrid
				columns={visibleColumns}
				rows={tableData}
				query={query?.query?.split(" ") ?? []}
				onSortColumnsChange={(col) => setOrder(col.map((c) => c.key))}
				rowKeyGetter={(row) => row.codiceEnte}
				totalResults={data.totalResults}
				selectedRows={selectedRows}
				setSelectedRows={setSelectedRows}
				setPage={(page) => setQuery({ ...query, page })}
				currPage={query.page}
				sortData={sort}
				onSortDataChange={setSort}
			/>
			<br />
			<br />
			<br />
		</div>
	);
}
