/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
/* eslint-disable eqeqeq */
/* eslint-disable implicit-arrow-linebreak */
import {
	TextField,
	FormControlLabel,
	Switch, FormControl, InputLabel, Select, MenuItem, Button
} from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Controller, useWatch, FormProvider } from 'react-hook-form';
import { useFetch } from '../../Hooks/useFetch';
import ToolsArea from '../Components/ToolsArea';
import DeleteItem from "../Components/DeleteItem";
import EditModForm, { EditFormContext } from "../Components/Forms/EditModForm";
import EnteSelect from "../Components/Forms/EnteSelect";
import StruttureOrganizzative from "../Components/Forms/StruttureOrganizzative";
import CategoriaTributo from "../Components/Forms/CategoriaTributo";
import StatoTributo from "../Components/Forms/StatoTributo";
import PluginSettingsInterface from "../../Plugins/PluginSettingsInterface";
import configuration from "../../configuration";
import useToken from "../../Hooks/useToken";
import MultiBenTable from "../Components/Forms/MultiBenTable";
import SpinnyPage from "../Components/SpinnyPage";
import ConfirmModal from "../Components/ConfirmModal";
import useTableColumns from "../Utils/useTableColumns";
import { DataGridSelectColumn } from "../Components/Tables/DataGridSelect";
import DataGrid from "../Components/Tables/DataGrid";

const Strutture = ({ control, setValue, mode }) => {
	const ente = useWatch({ control, name: 'ente.codiceEnte' });
	const allStrutture = useWatch(({ control, name: "allStrutture" }));
	const { data: strutture, status } = useFetch('/struttura/list', ente, 'GET');

	const [state, dispatch] = useContext(EditFormContext) ?? [null, () => {}];
	const previousState = useRef("");

	useEffect(() => {
		if (previousState.current !== status && status === "fetching") dispatch({ type: "AddCounter" });
		if (previousState.current !== status && status === "fetched") dispatch({ type: "DecreaseCounter" });
		if (previousState.current !== "fetching" && status === "idle") dispatch({ type: "ResetCounter" });
		previousState.current = status;
	}, [status]);

	useEffect(() => {
		if (mode !== 'edit') {
			setValue("struttureAmmesse", []);
			setValue("allStrutture", false);
		}
	}, [ente]);

	if (!strutture) return (<></>);

	const linearizeData = (rows) => rows.reduce((acc, d) => [
		...acc, d, ...linearizeData(d.figli)
	], []);
	const linearizedStrutture = linearizeData(strutture.data);

	return linearizedStrutture?.filter((x) => x.codiceEnte === ente).length > 0 ? (
		<div className="mt-3">
			<Controller
				name="allStrutture"
				defaultValue={false}
				control={control}
				render={({ field: { onChange, value } }) =>
					<FormControlLabel control={<Switch onChange={onChange} checked={value || false} />} label="Tutte le strutture organizzative" />}
			/>
			{
				!allStrutture && (
					<StruttureOrganizzative
						control={control}
						str={linearizedStrutture.filter((x) => x.codiceEnte === ente)}
					/>
				)
			}
		</div>
	) : (<></>);
};


export default function Tributi({ account }) {
	// - CRUD and Selection
	const [createEditMode, setCreateEditMode] = useState(false);
	const [selectedTrb, setSelectedTrb] = useState(null);
	const [ricevutaModData, setRicevutaModData] = useState({});
	const [selectedRows, setSelectedRows] = useState(new Map());
	// - Modals
	const [deleteOpen, setDeleteOpen] = useState(false);
	const [confirmModRicevutaOpen, setConfirmModRicevutaOpen] = useState(false);
	const [failedModRicevutaOpen, setFailedModRicevutaOpen] = useState(false);
	const [successModRicevutaOpen, setSuccessModRicevutaOpen] = useState(false);
	const [successInvioNotifiche, setSuccessInvioNotifiche] = useState(false);
	const [failedInvioNotifiche, setFailedInvioNotifiche] = useState(false);
	const [confirmInvioNotifiche, setConfirmInvioNotifiche] = useState(false);
	// - Query
	const [refresh, setRefresh] = useState(0);
	const [query, setQuery] = useState({ page: 0, pageSize: 20 });
	// - Permessi
	const canModify = ["All", "GestioneTributi"].some((p) => account.ruolo.permessi.includes(p));
	const canNotify = ["All", "InvioNotificheTributi"].some((p) => account.ruolo.permessi.includes(p));
	const canModRicevuta = ["ModRicevutaTributi"].some((p) => account.ruolo.permessi.includes(p));

	const enteOperatore = account.ente?.codiceEnte;

	// - Fetch
	const { data } = useFetch('/tributo/query', null, 'POST', query, refresh);

	if (data) data.data = data.data?.map((x) => ({ ...x, id: `${x.codiceEnte}///${x.codiceTributo}` }));

	const buttonModRicevuta = (r) => (canModRicevuta && r.row.modificaInvioRicevuta
		? (
			<Button
				variant="contained"
				style={{ height: "20px", boxShadow: "none", fontSize: "15px" }}
				onClick={() => {
					setConfirmModRicevutaOpen(true);
					setRicevutaModData(
						{ codTributo: r.row.codiceTributo, inviaRicevuta: !r.row.inviaRicevuta }
					);
				}}
			>
				{r.row.inviaRicevuta ? "Disattiva" : "Attiva"}
			</Button>
		)
		: <></>
	);

	const { visibleColumns, allColumns, setVisible, visible, setOrder, sort, sortFilter, setSort } = useTableColumns("tributi", [
		canNotify && DataGridSelectColumn,
		!enteOperatore && { key: "codiceEnte", name: "Codice ente", sortColumn: "idTributo.codiceEnte", sortable: true, frozen: false /*true*/, draggable: false, resizable: true, highlight: true, minWidth: 200, width: 240 },
		{ key: "codiceTributo", name: "Codice", sortColumn: "idTributo.codiceTributo", sortable: true, frozen: false /*true*/, draggable: false, highlight: true, resizable: true, minWidth: 200, width: 200 },
		{ key: "categoria", name: "Categoria", sortColumn: "categoria.nome", sortable: true, frozen: false, draggable: true, highlight: true, resizable: true, minWidth: 200, width: 200 },
		{ key: "denominazione", name: "Denominazione", sortColumn: "denominazione", sortable: true, frozen: false, draggable: true, highlight: true, minWidth: 500, resizable: true },
		{ key: "dsi", name: "Dati Specifici Incasso", sortColumn: "dsi", sortable: true, draggable: true, resizable: true, highlight: true, minWidth: 200, width: 200 },
		{ key: "spontaneo", name: "Spontaneo", sortColumn: "spontaneo", sortable: true, draggable: true, resizable: true, highlight: true, minWidth: 100, width: 100 },
		{ key: "stato", name: "Stato", sortColumn: "stato", sortable: true, draggable: true, resizable: true, width: 80 },
		canModRicevuta && { key: "modInviaRicevuta", name: "Invio Automatico Ricevuta", sortable: false, frozen: false, draggable: true, highlight: false, resizable: false, minWidth: 200, width: 200, formatter: buttonModRicevuta }
	], [DataGridSelectColumn.key, !enteOperatore && "codiceEnte", "categoria", "codiceTributo", "denominazione", "dsi", "spontaneo", "stato", canModRicevuta && "modInviaRicevuta"]);

	useEffect(() => setQuery((q) => ({ ...q, orderBy: sortFilter })), [JSON.stringify(sortFilter)]);

	const tableData = data?.data?.map((r) => ({
		id: r.id,
		codiceEnte: r.codiceEnte ?? "",
		codiceTributo: r.codiceTributo ?? "",
		categoria: r.categoria.nome ?? "",
		denominazione: r.denominazione ?? "",
		dsi: r.dsi ?? "",
		spontaneo: r.spontaneo ? "SI" : "NO",
		stato: r.stato ?? "",
		modificaInvioRicevuta: r.modificaInvioRicevuta,
		inviaRicevuta: r.inviaRicevuta
	}));

	const selected = [...selectedRows];

	// - Prendo il token JWT
	const jwt = useToken();

	const updateInvioRicevuta = async (codTributo, inviaRicevuta) => {
		const body = { inviaRicevuta };
		const response = await fetch(`${configuration.serverAddress}/tributo/${codTributo}/patchRicevuta`, {
			method: 'PATCH',
			body: JSON.stringify(body),
			headers: new Headers({
				Authorization: `Bearer ${jwt}`,
				...enteOperatore,
				...configuration.defaultHeaders
			})
		});

		const jsonResponse = await response.json();

		if (response.status >= 400) {
			setFailedModRicevutaOpen(true);
			return;
		}
		setSuccessModRicevutaOpen(true);
		setRicevutaModData({});
	};

	const inviaNotificaTributoPostCreazione = async (formData) => {
		if (!formData.notificaTributo) return;

		const response = await fetch(`${configuration.serverAddress}/email/notify`, {
			method: "POST",
			body: JSON.stringify([{ codiceEnte: formData.ente.codiceEnte, codiceTributo: formData.codiceTributo }]),
			headers: new Headers({
				Authorization: `Bearer ${jwt}`,
				...configuration.defaultHeaders
			})
		});

		const jsonResponse = await response.json();
		if (jsonResponse === true) setSuccessInvioNotifiche(true);
		else setFailedInvioNotifiche(true);
	};

	const inviaNotificaTributoMultiplo = async () => {
		const response = await fetch(`${configuration.serverAddress}/email/notify`, {
			method: "POST",
			body: JSON.stringify(selected.map((x) => ({ codiceEnte: x.split("///")[0], codiceTributo: x.split("///")[1] }))),
			headers: new Headers({
				Authorization: `Bearer ${jwt}`,
				...configuration.defaultHeaders
			})
		});

		const jsonResponse = await response.json();
		if (jsonResponse === true) setSuccessInvioNotifiche(true);
		else setFailedInvioNotifiche(true);
	};

	const editCreateForm = ({ register, control, mode, setValue, getValues }) => {
		const spontaneo = useWatch({ control, name: "spontaneo" });

		return (
			<FormProvider {...{ register, control }}>
				<div className="flex-column d-flex ">
					<div className="flex-row d-flex ">
						<div className="w-100">
							<Controller name="ente.codiceEnte" rules={{ required: true }} control={control} render={({ field: { onChange, value } }) => <EnteSelect onChange={onChange} value={value} required disabled={mode === 'edit'} />} />
							<TextField required {...register('codiceTributo')} disabled={mode === 'edit'} label="Codice tipologia di pagamento" inputProps={{ maxLength: 50 }} variant="standard" className="mt-2 w-100" />
							<TextField required {...register('denominazione')} label="Denominazione" inputProps={{ maxLength: 100 }} variant="standard" className="mt-2 w-100" style={{ width: '180px' }} id="cognome" />
							<div className="mt-2"><CategoriaTributo control={control} required /></div>
							<TextField required {...register('dsi')} label="DSI" inputProps={{ maxLength: 12 }} variant="standard" className="mt-2 w-100" />
							<Strutture control={control} setValue={setValue} mode={mode} />
							<div className="mt-3">
								<h4>Sistema</h4>
								<Controller name="spontaneo" defaultValue={false} control={control} render={({ field: { onChange, value } }) => <FormControlLabel control={<Switch onChange={onChange} checked={value || false} />} label="Abilita pagamento spontaneo" />} />
								<Controller name="notifica" defaultValue={false} control={control} render={({ field: { onChange, value } }) => <FormControlLabel control={<Switch onChange={onChange} checked={value || false} />} label="Abilita la notifica di pagamento" />} />
								{!spontaneo
									&& <Controller name="formRidotto" defaultValue={false} control={control} render={({ field: { onChange, value } }) => <FormControlLabel control={<Switch onChange={onChange} checked={value || false} />} className="w-100" label="Mostra form ridotto per il caricamento del debito" />} />
								}
								<StatoTributo control={control} />
							</div>
							<div className="mt-3">
								<h4>Notifica Ricevuta di Pagamento</h4>
								<Controller name="inviaRicevuta" defaultValue={false} control={control} render={({ field: { onChange, value } }) => <FormControlLabel control={<Switch onChange={onChange} checked={value || false} />} label="Abilita invio automatico Ricevuta di Pagamento" />} />
								<br />
								<Controller name="modificaInvioRicevuta" defaultValue={false} control={control} render={({ field: { onChange, value } }) => <FormControlLabel control={<Switch onChange={onChange} checked={value || false} />} label="Abilita modifica invio automatico per gli Operatori" />} />
							</div>
						</div>
						<div className="pl-5 w-100">
							<h4>Pagamento</h4>
							<Controller name="modello1" defaultValue control={control} render={({ field: { onChange, value } }) => <FormControlLabel control={<Switch onChange={onChange} checked={value || false} />} className="w-100" label="Abilita pagamento modello 1 (Online)" />} />
							<Controller name="modello3" defaultValue control={control} render={({ field: { onChange, value } }) => <FormControlLabel control={<Switch onChange={onChange} checked={value || false} />} className="w-100" label="Abilita pagamento modello 3 (PSP)" />} />
							<TextField {...register('ibanBancario')} inputProps={{ maxLength: 256 }} required={getValues("ibanCCP") === ""} label="Iban bancario" variant="standard" className="w-100" />
							<TextField {...register('ibanCCP')} inputProps={{ maxLength: 256 }} required={getValues("ibanBancario") === ""} label="Iban conto corrente postale" variant="standard" className="mt-2 w-100" />
							<TextField {...register('auxDigit')} inputProps={{ maxLength: 1 }} required label="Aux digit" variant="standard" className="mt-2 w-25" />
							<TextField {...register('codiceSegregazione')} inputProps={{ maxLength: 2 }} required label="Cod. segr." variant="standard" className="mt-2 w-25 ml-3" />
							<TextField {...register('prefissoIuv')} required type="number" label="Prefisso IUV" variant="standard" className="mt-2 ml-3" style={{ width: '155px' }} />
							<div className="mt-3">
								<h4>App IO</h4>
								<Controller name="abilitaIo" defaultValue={false} control={control} render={({ field: { onChange, value } }) => <FormControlLabel control={<Switch onChange={onChange} checked={value || false} />} label="Abilita il servizio nell'app IO" />} />
								<TextField {...register('emailRefIo')} type="email" label="Email referente servizio" variant="standard" className="w-100" />
								<TextField {...register('telefonoRefIo')} inputProps={{ maxLength: 20 }} label="Telefono referente servizio" variant="standard" className="mt-2 w-100" />
							</div>
						</div>
					</div>
					<div className="mt-3 w-100">
						<h3 className="mb-4">Iban multibeneficiario ammessi</h3>
						<Controller
							name="ibanAmmessi"
							defaultValue={{	}}
							control={control}
							render={({ field: { onChange, value } }) => (
								<MultiBenTable onChange={onChange} value={value} />
							)}
						/>
					</div>
					<div className="mt-4">
						<h3 className="mb-4">Configurazione plugin</h3>
						<PluginSettingsInterface />
					</div>
					{createEditMode === "create" && (
						<div className="mt-5" style={{ border: "1px solid lightgrey", borderRadius: "5px", paddingLeft: "20px", paddingRight: "20px", paddingTop: "5px", paddingBottom: "5px", width: "300px", alignSelf: "center" }}>
							<Controller
								name="notificaTributo"
								defaultValue={false}
								control={control}
								render={({ field: { onChange, value } }) =>
									<FormControlLabel control={<Switch onChange={onChange} checked={value} />} label="Invia notifica all'operatore" style={{ marginBottom: 0 }} />} />
						</div>
					)}
				</div>
			</FormProvider>
		);
	};

	useEffect(() => {
		const action = async () => {
			if (selected.length === 1 && createEditMode === "edit") {
				const tributo = data.data.filter((d) => d.id === selected[0])[0];

				const response = await fetch(`${configuration.serverAddress}/tributo/${tributo.codiceEnte}/${tributo.codiceTributo}/all`, {
					method: "GET",
					headers: new Headers({
						Authorization: `Bearer ${jwt}`,
						...configuration.defaultHeaders
					})
				});

				setSelectedTrb(await response.json());
			} else setSelectedTrb(null);
		};
		action();
	}, [createEditMode]);
	if (!data) return <SpinnyPage />;

	data.data = data.data.map((x) => ({ ...x, id: `${x.codiceEnte}///${x.codiceTributo}` }));

	const FilterFields = [
		[
			!enteOperatore ? (r) => (<TextField {...r('ente.codiceEnte')} label="Codice ente" variant="standard" inputProps={{ maxLength: 50 }} />) : (r) => {},
			(r) => (<TextField {...r('codiceTributo')} label="Codice tributo" variant="standard" />),
			(r) => (<TextField {...r('denominazione')} label="Denominazione" variant="standard" />),
			(r) => (<TextField {...r('dsi')} label="Dati Specifici di Incasso" variant="standard" />),
			(r, c) => (
				<Controller
					name="spontaneo"
					defaultValue=""
					control={c}
					render={({ field: { onChange, value } }) => (
						<div style={{ width: '150px' }}>
							<FormControl variant="standard" className="w-100">
								<InputLabel>Spontaneo</InputLabel>
								<Select onChange={onChange} value={value}>
									<MenuItem value="">Nessun filtro</MenuItem>
									<MenuItem value>Spontaneo</MenuItem>
									<MenuItem value={false}>Non spontaneo</MenuItem>
								</Select>
							</FormControl>
						</div>
					)}
				/>
			)
		]
	];

	return (
		<div>
			<ConfirmModal
				open={confirmModRicevutaOpen}
				title="Conferma Operazione"
				text="Desideri confermare la modifica della proprietà?"
				onConfirm={() => {
					updateInvioRicevuta(ricevutaModData.codTributo, ricevutaModData.inviaRicevuta)
						.then((r) => {
							setConfirmModRicevutaOpen(false);
							setRefresh(refresh + 1);
						});
				}}
				onDeny={() => {
					setConfirmModRicevutaOpen(false);
					setRicevutaModData({});
				}}
				confirmText="OK"
			/>
			<ConfirmModal
				open={failedModRicevutaOpen}
				title="Modifica della proprietà Invia Ricevuta fallita"
				text="Si è verificato un errore. Se il problema persiste, contattare l'assistenza."
				onConfirm={() => setFailedModRicevutaOpen(false)}
				confirmText="OK"
			/>
			<ConfirmModal
				open={successModRicevutaOpen}
				title="Modifica della proprietà Invia Ricevuta"
				text="Modifica effettuata con successo."
				onConfirm={() => setSuccessModRicevutaOpen(false)}
				confirmText="OK"
			/>
			<ConfirmModal
				open={confirmInvioNotifiche}
				title="Conferma notifiche"
				text="Desideri generare le mail di notifica per le Tipologie di Pagamento selezionate?"
				onConfirm={() => {
					inviaNotificaTributoMultiplo();
					setConfirmInvioNotifiche(false);
				}}
				onDeny={() => setConfirmInvioNotifiche(false)}
				confirmText="OK"
			/>
			<ConfirmModal
				open={successInvioNotifiche}
				title="Notifiche generate con successo"
				text="Sono state generate le mail di notifica per le Tipologie di Pagamento selezionate."
				onConfirm={() => setSuccessInvioNotifiche(false)}
				confirmText="OK"
			/>
			<ConfirmModal
				open={failedInvioNotifiche}
				title="Notifiche fallite"
				text="Le mail di notifica non sono state generate, si è verificato un errore."
				onConfirm={() => setFailedInvioNotifiche(false)}
				confirmText="OK"
			/>
			<DeleteItem
				open={deleteOpen}
				query="/tributo/delete"
				entityNamePlural="tipologie di pagamento"
				entityNameSingle="tipologia di pagamento"
				items={selected.map((item) => {
					const trb = data.data.find((x) => x.id === item);
					return trb
						? { codiceTributo: trb.codiceTributo, ente: { codiceEnte: trb.codiceEnte } }
						: {};
				})}
				onCancel={() => setDeleteOpen(false)}
				onDeleted={() => { setDeleteOpen(false); setRefresh(refresh + 1); setSelectedRows(new Set()); }}
				onError={() => { setDeleteOpen(false); setSelectedRows(new Set()); }}
			/>
			<EditModForm
				path={`/tributo${createEditMode === 'edit' ? `/${data.data.filter((d) => d.id === selected[0])[0].codiceTributo}` : ''}`}
				entityName="Tipologia di pagamento"
				ente={createEditMode === 'edit' ? data.data.filter((d) => d.id === selected[0])[0].codiceEnte : null}
				mode={createEditMode}
				data={createEditMode === 'edit' ? selectedTrb : {}}
				onCancel={() => setCreateEditMode(false)}
				onSuccess={(formData) => {
					if (createEditMode !== "edit") {
						inviaNotificaTributoPostCreazione(formData);
					}
					setCreateEditMode(false);
					setRefresh(refresh + 1);
				}}
				formElements={editCreateForm}
			/>
			<ToolsArea
				className="mt-4 mb-3"
				selected={selected}
				setSelected={setSelectedRows} //Serve per svuotare gli operatori selezionati se effettuo una ricerca
				queryParameters={[
					'ente.codiceEnte', 'denominazione', 'idTributo.codiceTributo', 'dsi'
				]}
				disableDelete={!canModify}
				disableEdit={!canModify}
				disableNew={!canModify}
				filtersChanged={(filters) => setQuery({ ...query, ...filters })}
				deleteCallback={() => setDeleteOpen(true)}
				createCallback={() => setCreateEditMode('create')}
				editCallback={() => setCreateEditMode('edit')}
				notifyCallback={() => setConfirmInvioNotifiche(true)}
				disableNotify={!canNotify}
				fields={FilterFields}
				allColumns={allColumns}
				visibleColumns={visible}
				setVisibleColumns={setVisible}
			/>
			<DataGrid
				columns={visibleColumns}
				rows={tableData}
				query={query?.query?.split(" ") ?? []}
				onSortColumnsChange={(col) => setOrder(col.map((c) => c.key))}
				rowKeyGetter={(row) => row.id}
				totalResults={data.totalResults}
				selectedRows={selectedRows}
				setSelectedRows={setSelectedRows}
				setPage={(page) => setQuery({ ...query, page })}
				currPage={query.page}
				sortData={sort}
				onSortDataChange={setSort}
			/>
			<br />
			<br />
			<br />
		</div>
	);
}
