/* eslint jsx-a11y/click-events-have-key-events: 0 */
import React, { useState } from "react";
import { Divider, TextField } from "@mui/material";
import NumberFormat from "react-number-format";
import { Button } from "design-react-kit";
import Breadcrumbs from "../Components/Common/Breadcrumbs";
import TitleBar from "../Components/Common/TitleBar";
import InputConDescrizione from "../Components/Common/InputConDescrizione";
import { useFetch } from "../../Hooks/useFetch";

function DomandaFaq({ titolo, children }) {
	const [open, setOpen] = useState(false);

	return (
		<div>
			<button type="button" onClick={() => setOpen((o) => !o)} style={{ fontSize: "25px", fontWeight: 600, display: "flex", flexDirection: "row", columnGap: "20px", alignItems: "center", paddingLeft: "10px", lineHeight: "30px", userSelect: "none", cursor: "pointer", background: "none", border: "none" }}>
				<i className={open ? "fa fa-fw fa-chevron-down" : "fa fa-fw fa-chevron-right"} />
				{titolo}
			</button>
			<hr className="mt-2" />
			{open && <div className="mb-4">{children}</div>}
		</div>
	);
}
// Societa partecipate 2
// enti di ricerca 3
// enti locali 246
// enti regionali 17
export default function Progetto() {
	const [body, setBody] = useState("");
	const [ente, setEnte] = useState("");

	const { data: statistiche, status } = useFetch('/stats/statsProgetto', null, 'GET');

	const breadcrumbs = [
		{ titolo: 'progetto' }
	];

	return (
		<>
			<Breadcrumbs breadcrumbs={breadcrumbs} />
			<div style={{ boxShadow: "0px 10px 10px #0000001A", marginBottom: "40px", paddingBottom: "20px" }}>
				<section id="progetto" className="container">
					<div className="d-flex flex-row position-relative flex-wrap align-items-center justify-content-center">
						<div style={{ width: "260px", height: "470px", position: "relative" }}>
							<img src="/images/sardegna-dots.png" alt="Sardegna" height="470" style={{ position: "absolute", top: 0, left: 0 }} />
							<div className="position-absolute align-items-center flex-column d-flex" style={{ left: "70px", top: "200px" }}>
								<span style={{ color: "#1D2D7E", fontWeight: "500", fontSize: "25px", lineHeight: "26px", textShadow: "2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff, 1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff" }}>Enti aderenti</span>
								<span style={{ color: "#1D2D7E", fontWeight: "600", fontSize: "28px", lineHeight: "26px", textShadow: "2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff, 1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff" }}>{statistiche?.numeroEnti}</span>
							</div>
							<div className="position-absolute align-items-center flex-column d-flex" style={{ left: "20px", top: "370px" }}>
								<span style={{ color: "#1D2D7E", fontWeight: "600", fontSize: "23px", lineHeight: "26px", textShadow: "2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff, 1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff" }}>17</span>
								<span style={{ color: "#1D2D7E", fontWeight: "500", fontSize: "20px", lineHeight: "26px", textShadow: "2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff, 1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff" }}>Enti regionali</span>
							</div>
							<div className="position-absolute align-items-center flex-column d-flex" style={{ left: "40px", top: "120px" }}>
								<span style={{ color: "#1D2D7E", fontWeight: "500", fontSize: "20px", lineHeight: "26px", textShadow: "2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff, 1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff" }}>Enti di ricerca</span>
								<span style={{ color: "#1D2D7E", fontWeight: "600", fontSize: "23px", lineHeight: "26px", textShadow: "2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff, 1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff" }}>3</span>
							</div>
							<div className="position-absolute align-items-center flex-column d-flex" style={{ left: "80px", top: "270px", width: "155px", textAlign: "center" }}>
								<span style={{ color: "#1D2D7E", fontWeight: "500", fontSize: "20px", lineHeight: "26px", textShadow: "2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff, 1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff" }}>Società partecipate</span>
								<span style={{ color: "#1D2D7E", fontWeight: "600", fontSize: "23px", lineHeight: "26px", textShadow: "2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff, 1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff" }}>2</span>
							</div>
							<div className="position-absolute align-items-center flex-column d-flex" style={{ left: "120px", top: "40px" }}>
								<span style={{ color: "#1D2D7E", fontWeight: "500", fontSize: "20px", lineHeight: "26px", textShadow: "2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff, 1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff" }}>Enti locali</span>
								<span style={{ color: "#1D2D7E", fontWeight: "600", fontSize: "23px", lineHeight: "26px", textShadow: "2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff, 1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff" }}>246</span>
							</div>
						</div>
						<div className="ml-5" style={{ flexBasis: "844px" }}>
							<h1>Progetto regionale</h1>
							pagoPA è un sistema nazionale per l&apos;effettuazione dei pagamenti elettronici nei confronti
							delle Pubbliche Amministrazioni, dei gestori di servizi pubblici e delle società a controllo
							pubblico, in attuazione dell&apos;art. 5 del D.Lgs. 82/2005 (Codice dell&apos;Amministrazione Digitale).
							<br />
							<br />
							Il sistema è basato su una piattaforma tecnologica per l&apos;interconnessione e l&apos;interoperabilità
							tra le pubbliche amministrazioni e i prestatori di servizi di pagamento abilitati, messa a
							disposizione dall&apos;Agenzia per l&apos;Italia Digitale ai sensi dell&apos;art. 5 comma 2 del D.Lgs. 7
							marzo 2005, n. 82 (Codice dell&apos;Amministrazione Digitale). È possibile trovare maggiori
							informazioni sul progetto nazionale sul sito della società pubblica pagoPA S.p.A.
							all&apos;indirizzo
							<a href="https://www.pagopa.gov.it/"> https://www.pagopa.gov.it/</a>
							<br />
							<br />
							Il portale pagoPA Sardegna è un sistema a disposizione degli enti della Sardegna che
							presentano richiesta di intermediazione pagoPA alla Regione Autonoma della Sardegna.
							Il sistema consente di pagare online, stampare gli avvisi da presentare presso i Prestatori
							di Servizi di Pagamento, l&apos;integrazione con applicativi terzi tramite web service, e
							visualizzare le pendenze e i pagamenti.
						</div>
					</div>
					<div className="d-flex flex-row mt-4 flex-wrap" style={{ justifyContent: "space-between", rowGap: "20px", color: "#1D2D7E" }}>
						<div>
							<h4 className="mb-1">Servizi di pagamento</h4>
							<div style={{ fontSize: "40px", fontWeight: 600, lineHeight: "40px" }}>
								<div style={{ width: "100px", display: "inline-block", textAlign: "right" }}>
									<NumberFormat
										value={statistiche?.pagamentiSpontanei}
										displayType="text"
										decimalScale={0}
										fixedDecimalScale
										decimalSeparator=","
										thousandSeparator="."
									/>
								</div>
								<span style={{ fontSize: "25px", fontWeight: 200 }}> spontanei</span>
							</div>
							<div style={{ fontSize: "40px", fontWeight: 600, lineHeight: "40px" }}>
								<div style={{ width: "100px", display: "inline-block", textAlign: "right" }}>
									<NumberFormat
										value={statistiche?.pagamentiDebitori}
										displayType="text"
										decimalScale={0}
										fixedDecimalScale
										decimalSeparator=","
										thousandSeparator="."
									/>
								</div>
								<span style={{ fontSize: "25px", fontWeight: 200 }}> debitori</span>
							</div>
						</div>
						<div>
							<h4 className="mb-1">Importo transitato nell&apos;ultimo anno</h4>
							<div style={{ fontSize: "50px", fontWeight: 600, lineHeight: "40px", textAlign: "center" }}>
								<span style={{ fontSize: "40px", fontWeight: 200 }}>€</span>
								<NumberFormat
									value={statistiche?.importoTransitato ?? 0}
									displayType="text"
									decimalScale={2}
									fixedDecimalScale
									decimalSeparator=","
									thousandSeparator="."
								/>
							</div>
						</div>
						<div>
							<h4 className="mb-1">Pagamenti nell&apos;ultimo anno</h4>
							<div style={{ fontSize: "50px", fontWeight: 600, lineHeight: "40px", textAlign: "center" }}>
								<NumberFormat
									value={statistiche?.pagamentiTotali ?? 0}
									displayType="text"
									decimalScale={0}
									fixedDecimalScale
									decimalSeparator=","
									thousandSeparator="."
								/>
							</div>
						</div>
					</div>
				</section>
			</div>
			<section id="progetto" className="container">
				<h1>Come aderire a pagoPA Sardegna</h1>
				<div style={{ width: "680px" }}>
					Di seguito sono riportati i passaggi che un ente della Sardegna interessato all&apos;utilizzo del portale
					pagoPA Sardegna deve seguire per esporre e consentire la gestione sul sistema di uno o più servizi di pagamento pagoPA.
				</div>
				<div className="d-flex flex-row align-items-stretch w-100 mt-4 flex-wrap" style={{ columnGap: "15px" }}>
					<div className="w-100 flex-grow-1" style={{ flexBasis: "260px" }}>
						<div style={{ color: "#1d2d7e", fontSize: "30px", fontWeight: 700, lineHeight: "30px", marginTop: "15px" }}>01</div>
						<div style={{ fontSize: "25px", fontWeight: 700, lineHeight: "24px" }}>Accesso all&apos;Area Riservata PagoPA</div>
						<div className="mt-4">
							Accedere all&apos;indirizzo
							<a href="https://selfcare.pagopa.it/auth/login"><b> Selfcare login </b></a>
							utilizzando un&apos;Identità Digitale fra le consentite. In caso non si disponesse delle
							autorizzazioni necessarie si può richiedere assistenza cliccando sul pulsante &quot;Assistenza&quot;
							in alto a destra sul sito.
						</div>
					</div>
					<div className="w-100 flex-grow-1" style={{ flexBasis: "260px" }}>
						<div style={{ color: "#1d2d7e", fontSize: "30px", fontWeight: 700, lineHeight: "30px", marginTop: "15px" }}>02</div>
						<div style={{ fontSize: "25px", fontWeight: 700, lineHeight: "24px" }}>Scelta della RAS come intermediario</div>
						<div className="mt-4">
							Accedere all&apos;Area Riservata PagoPA all&apos;indirizzo
							<a href="https://selfcare.pagopa.it/auth/login"><b> Selfcare login </b></a>
							e selezionare la Regione Autonoma della Sardegna quale intermediario tecnologico. La Regione,
							notificata, invierà la documentazione di adesione.
						</div>
					</div>
					<div className="w-100 flex-grow-1" style={{ flexBasis: "260px" }}>
						<div style={{ color: "#1d2d7e", fontSize: "30px", fontWeight: 700, lineHeight: "30px", marginTop: "15px" }}>03</div>
						<div style={{ fontSize: "25px", fontWeight: 700, lineHeight: "24px" }}>Invio documenti alla Regione</div>
						<div className="mt-4">
							Compilare e inviare alla Regione Autonoma della Sardegna i documenti da questa ricevuti
							(Accordo di adesione, Nomina trattamento dati personali, logo, elenco servizi d&apos;incasso da
							attivare, ed eventuali ulteriori informazioni).
						</div>
					</div>
					<div className="w-100 flex-grow-1" style={{ flexBasis: "260px" }}>
						<div style={{ color: "#1d2d7e", fontSize: "30px", fontWeight: 700, lineHeight: "30px", marginTop: "15px" }}>04</div>
						<div style={{ fontSize: "25px", fontWeight: 700, lineHeight: "24px" }}>Inserimento IBAN nell&apos;Area Riservata</div>
						<div className="mt-4">
							Provvedere al censimento nell&apos;Area Riservata PagoPA (
							<a href="https://selfcare.pagopa.it/auth/login"><b>Selfcare login</b></a>
							) del/degli IBAN da associare ai servizi d&apos;incasso da attivare. L&apos;ente riceverà poi
							aggiornamenti sui passaggi successivi per l&apos;attivazione, a carico della RAS.
						</div>
					</div>
				</div>
			</section>
			<div className="mt-4 mb-4" style={{ background: "#00000014" }}>
				<section id="progetto" className="container pt-2 pb-2">
					<h3 style={{ textTransform: "uppercase", marginBottom: "0px" }}>Inoltra una richiesta di informazioni</h3>
				</section>
			</div>
			<section id="progetto" className="container">
				<div>
					In caso di necessità di ottenere informazioni più approfondite su specifiche funzionalità del
					sistema pagoPA Sardegna, o in caso di chiarimenti su aspetti specifici del processo di adesione
					(p.e. servizi d&apos;incasso con personalizzazioni) è possibile inviare una richiesta di informazioni
					tramite il form di seguito disponibile:
				</div>
				<br />
				<div style={{ maxWidth: "500px" }}>
					<InputConDescrizione nome="codice" tipo="text" value={ente} onChange={(e) => setEnte(e.target.value)} placeholder="Nome dell'ente" />
					<br />
					<br />
				</div>
				<textarea className="form-control descrizione-input" value={body} onChange={(e) => setBody(e.target.value)} style={{ border: "1px solid #737272" }} placeholder="Contenuto della mail" />
				<br />
				<Button onClick={() => { window.location = `mailto:pagopa@regione.sardegna.it?subject=Info%20richiesta%20adesione%20${ente}&body=${body}`; }} color="primary">Invia</Button>
			</section>
			<section id="progetto" className="container mt-5">
				<h1>FAQ - Domande frequenti</h1>
				Questa sezione contiene una prima serie di risposte alle domande più frequenti
				<br />
				<br />
				<div style={{ display: "flex", flexDirection: "column", rowGap: "5px" }}>
					<DomandaFaq titolo="Quanto tempo richiede l&apos;attivazione dei servizi d&apos;incasso su pagoPA Sardegna?">
						L&apos;attivazione dei primi servizi pagoPA può richiedere qualche giorno lavorativo dopo l&apos;avvenuta
						conferma da parte dell&apos;ente di censimento del/degli IBAN nell&apos;Area Riservata, con data di
						attivazione concordata con l&apos;ente. In caso di prima intermediazione per pagoPA e/o utilizzo di
						IBAN postali (per cui è necessario richiedere l&apos;autorizzazione della stampa dei bollettini postali)
						potrebbe essere necessario attendere dai 7 ai 10 giorni prima che l&apos;attivazione sia possibile.
						Generalmente, tempistiche più lunghe sono da attribuire ad enti terzi rispetto alla
						Regione eventualmente coinvolti nel processo di adesione.
					</DomandaFaq>
					<DomandaFaq titolo="È possibile attivare servizi d'incasso anche nel caso se ne abbiano già alcuni con altro intermediario?">
						Sì, è possibile avere più intermediari per i servizi pagoPA.
					</DomandaFaq>
					<DomandaFaq titolo="Posso caricare più posizioni debitorie e inviare più avvisi di pagamento in un'unica operazione?">
						Sì, il sistema pagoPA Sardegna permette il caricamento massivo di posizioni debitorie tramite
						la predisposizione e il caricamento sulla piattaforma di un singolo file manipolabile con i
						comuni strumenti office, e anche l&apos;invio massivo di avvisature di pagamento per mezzo posta elettronica.
					</DomandaFaq>
					<DomandaFaq titolo="Gli operatori dell'ente potranno accedere al sistema tramite SPID?">
						Sì, il sistema pagoPA Sardegna permette l&apos;accesso tramite Identità Digitale SPID
						(e anche tramite Tessera Sanitaria e Carta d&apos;Identità Elettronica).  Non sarà dunque necessario
						custodire delle credenziali per l&apos;accesso al portale, né per gli operatori né per i cittadini.
					</DomandaFaq>
				</div>
			</section>
			<br />
			<br />
		</>
	);
}
