/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState, useCallback, useRef } from 'react';
import {
	Box,
	Button, Checkbox,
	ClickAwayListener, Divider,
	IconButton, ListItem,
	ListItemButton, ListItemIcon, ListItemText,
	Popover,
	TextField,
	Typography
} from '@mui/material';
import List from "@mui/material/List";
import { useForm, FormProvider } from 'react-hook-form';


import debounce from '../Utils/Debounce';
import styles from './ToolsArea.module.css';
import ButtonDescription from "./ButtonDescription";
import { removeEmptyObjects } from "../../Utils/Object";

export default function ToolsArea({
	fields = [], selected, setSelected, createCallback, deleteCallback, editCallback, disableEdit, disableDelete, actualFilters,
	disableNew, filtersChanged, exportCallback, className, enableExport, queryParameters, disableFilter,
	disableSearch, disableAdvancedSearch, callStatus, enableAvvisi, avvisiCallback, mailCallback, allColumns, visibleColumns, setVisibleColumns, reportCallback, notifyCallback, disableNotify
}) {
	const [filters, setFilters] = useState({});
	const [columnMenu, setColumnMenu] = useState(false);
	const debounceOnChange = useCallback(debounce(200, filtersChanged), []);
	const [advancedSearchOpen, setAdvancedSearchOpen] = useState(false);
	const form = useForm({ values: actualFilters });
	const { register, handleSubmit, control, setValue, resetField } = form;

	const filterRef = useRef();

	useEffect(() => {
		form.reset(actualFilters);
	}, [actualFilters, form]);

	useEffect(() => {
		if (filtersChanged) debounceOnChange({ ...filters, queryParameters });
	}, [filters]);

	useEffect(() => {
		setFilters((q) => ({ ...q, query: actualFilters?.query }));
	}, [actualFilters?.query]);

	const submit = (data) => {
		setFilters(removeEmptyObjects({ ...filters, ...data }));
	};

	const toggleRow = (row) => () => {
		if (visibleColumns.includes(row)) {
			setVisibleColumns(visibleColumns.filter((r) => row !== r));
		} else setVisibleColumns([...visibleColumns, row]);
	};

	const toggleAll = () => {
		if (visibleColumns.length === allColumns.length) setVisibleColumns(allColumns.filter((c) => c.frozen).map((c) => c.key));
		else setVisibleColumns(allColumns.map((c) => c.key));
	};

	return (
		<FormProvider {...form}>
			<div className={`${styles.toolsArea} ${className} rounded p-3`}>
				<div className="d-flex flex-row">
					{
						enableExport && (
							<ButtonDescription label="Export CSV" onClick={exportCallback} className="fas fa-fw fa-file-download" description="Export CSV" />)
					}
					{
						enableAvvisi && (
							<ButtonDescription label="Scarica Avvisi" onClick={avvisiCallback} className="fas fa-download" description="Scarica Avvisi" />)
					}
					{
						enableAvvisi && (
							<ButtonDescription label="Invia Email" onClick={mailCallback} className="fas fa-envelope-open-text" description="Invia Email" />)
					}
					{
						reportCallback && (
							<ButtonDescription label="Genera Report" onClick={reportCallback} className="fas fa-plus" description="Report" />)
					}
					{
						!disableNew && (
							<ButtonDescription label="Aggiungi nuovo elemento" onClick={createCallback} className="fas fa-fw fa-plus" description="Nuovo" />)
					}
					{
						notifyCallback && !disableNotify && selected && selected.length >= 1 && (
							<ButtonDescription label="Invia notifiche" onClick={notifyCallback} className="fas fa-paper-plane" description="Notifica" />)
					}
					{
						!disableEdit && selected && selected.length === 1 && (
							<ButtonDescription label="Modifica elemento" onClick={editCallback} className="fas fa-fw fa-edit" description="Modifica" />)
					}
					{
						!disableDelete && selected && selected.length >= 1 && (
							<ButtonDescription label="Cancella selezionati" onClick={deleteCallback} className="fas fa-fw fa-trash" description="Elimina" />)
					}
					{
						!disableFilter && (
							<div ref={filterRef}>
								<ButtonDescription label="Filtra colonne" onClick={(e) => { setColumnMenu(!columnMenu); e.stopPropagation(); }} className="fas fa-fw fa-filter" description="Filtra" />
								<Popover open={columnMenu} anchorEl={filterRef.current} anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>
									<ClickAwayListener onClickAway={() => setColumnMenu(false)}>
										<Box height="500px" width="300px">
											<div style={{ padding: "20px" }}>
												<h2>Colonne</h2>
												<Typography>È possibile nascondere o mostrare le colonne spuntando le checkbox nella lista.</Typography>
											</div>
											<List sx={{ width: '100%', maxHeight: "300px", overflow: "scroll" }}>
												<ListItem disablePadding>
													<ListItemButton role={undefined} onClick={toggleAll} dense>
														<ListItemIcon>
															<Checkbox
																edge="start"
																checked={visibleColumns.length === allColumns.length}
																tabIndex={-1}
																disableRipple
																inputProps={{ 'aria-labelledby': "all" }}
															/>
														</ListItemIcon>
														<ListItemText id="all" primary="Tutte le colonne" />
													</ListItemButton>
												</ListItem>
												<Divider />
												{
													allColumns?.filter((r) => r.name).map((col) => (
														<ListItem key={col.key} disablePadding>
															<ListItemButton role={undefined} onClick={toggleRow(col.key)} dense>
																<ListItemIcon>
																	<Checkbox
																		edge="start"
																		checked={visibleColumns.includes(col.key)}
																		tabIndex={-1}
																		disableRipple
																		inputProps={{ 'aria-labelledby': col.key }}
																	/>
																</ListItemIcon>
																<ListItemText id={col.key} primary={`${col.name}`} />
															</ListItemButton>
														</ListItem>
													))
												}
											</List>
											<Button className="mt-1 mb-1 mr-3 float-right" onClick={() => setColumnMenu(false)}>Chiudi</Button>
										</Box>
									</ClickAwayListener>
								</Popover>
							</div>
						)
					}
					{
						!disableSearch && (
							<>
								<TextField
									onChange={(event) => {
										setFilters(removeEmptyObjects({
											...filters,
											query: event.target.value
										}));
										if (setSelected) {
											setSelected(new Map());
										} // svuota selectedRows quando si effettua una ricerca
									}}
									value={filters?.query ?? ''}
									variant="standard"
									className="w-75 align-middle mt-2 ml-3"
								/>
								{
									!disableAdvancedSearch && (
										<Button
											className="ml-auto"
											sx={{ fontWeight: !advancedSearchOpen ? "normal" : "bold" }}
											size="small"
											onClick={() => setAdvancedSearchOpen(!advancedSearchOpen)}
										>
											Ricerca avanzata
										</Button>
									)
								}
							</>
						)
					}
				</div>
				{
					advancedSearchOpen && (
						<div className={styles.advancedSearch}>
							<div className="d-flex flex-column mt-3">
								{
									typeof fields === 'function' ? React.createElement(fields, { register, control, setValue, resetField }) : fields.map((row, ri) => (
										<div key={`${ri + 1}`} className="d-flex flex-row flex-wrap align-items-end mb-3">
											{
												row.map((field, index) => (
													<div className="ml-2 mr-2 w-auto" key={`${ri + 1}${index + 1}`}>
														{field(register, control)}
													</div>
												))
											}
										</div>
									))
								}
							</div>
							{/* eslint-disable-next-line */}
							<div align="right">
								<Button
									className="ml-auto"
									onClick={() => {
										if (setSelected) {
											setSelected(new Map());
										}
										handleSubmit(submit)();
									}}
									disabled={callStatus === "fetching"}
								>
									{callStatus !== "fetching" ? "Cerca" : "Caricamento..."}
								</Button>
							</div>
						</div>
					)
				}
			</div>
		</FormProvider>
	);
}
